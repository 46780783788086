import { lazy, Suspense } from "react";
// import Loading from "../Screen/Loading/Loading";
import { Route, Navigate } from "react-router-dom";
import routesPath from "./Path";
import ErrorBoundary from "../Screen/Error/ErrorBoundary";
import SLUGS from "../Resources/SLUG/Slugs";
import Cookies from "universal-cookie";
import { BrowserRouter, Routes } from "react-router-dom";
import Cubeloader from "../Components/Loadercmp/Cubeloader";
// import Footer from "../Screen/Footer/Footer";

const Header = lazy(() => {
  return import("../Components/Layout/Header");
});
const cookies = new Cookies();

const isAuthenticated = cookies.get("@evr_storage_access_token");

function RequireAuth({ children, redirectTo }) {
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function NotRequireAuth({ children, redirectTo }) {
  return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}
export const PrivateRoutes = () => (
  <BrowserRouter>
    <Header />
    <div className="px-5 py-2">
      <Suspense fallback={<Cubeloader />}>
        <Routes>
          {routesPath.Private.map((c, i) => (
            <Route
              path={c.path}
              key={i + 1}
              element={
                <RequireAuth redirectTo={SLUGS.login}>
                  <ErrorBoundary>{c.component}</ErrorBoundary>
                </RequireAuth>
              }
            ></Route>
          ))}
        </Routes>
      </Suspense>
      {/* <Footer /> */}
    </div>
  </BrowserRouter>
);

export const PublicPath = () => (
  <>
    <BrowserRouter>
      <Suspense fallback={<Cubeloader />}>
        <Routes>
          {routesPath.Public.map((c, i) => (
            <Route
              path={c.path}
              key={i + 1}
              element={
                <NotRequireAuth redirectTo={SLUGS._dashboardPath}>
                  <ErrorBoundary>{c.component}</ErrorBoundary>
                </NotRequireAuth>
              }
            ></Route>
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  </>
);
