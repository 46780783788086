import React from "react";
import carImage from "../../Assets/gif/Loading-Cars1.gif";
import "./cubeloader.css";
const Cubeloader = () => {
  return (
    <div className="min-h-screen">
      <div className="container_image ">
        <img
          alt="loading"
          src={carImage}
          // src={
          //   "https://res.cloudinary.com/efer/image/upload/v1643811385/VivaDrive/Logos/Products/EVRLogo_hci8ou.svg"
          // }
          className="pt-10 w-60"
        />
      </div>
    </div>
    // <div class="container">
    //   <div className="cube">
    //     <div className="sides">
    //       <div className="top"></div>
    //       <div className="right"></div>
    //       <div className="bottom"></div>
    //       <div className="left"></div>
    //       <div className="front"></div>
    //       <div className="back"></div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Cubeloader;
