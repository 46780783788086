import { lazy } from "react";
import SLUGS from "../Resources/SLUG/Slugs";

/* 
==========================================
 Public Router Path
==========================================
*/
const Forget = lazy(() => {
  return import("../Screen/Authentication/Forget");
});
const Login = lazy(() => {
  return import("../Screen/Authentication/Login");
});
const NewPassword = lazy(() => {
  return import("../Screen/Authentication/NewPassword");
});

/* 
==========================================
 Private Router Path
==========================================
*/
const Dashboard = lazy(() => {
  return import("../Screen/Dashboard/Dashboard");
});
const AnalyticsDashboard = lazy(() => {
  return import("../Screen/AnalyticsDashboard/AnalyticsDashboard");
});
const Deployment = lazy(() => {
  return import("../Screen/DeploymentRecommendation/");
});
// const Deployment1 = lazy(() => {
//   return import("../Screen/DeploymentRecommendation/Step1");
// });
// const Deployment2 = lazy(() => {
//   return import("../Screen/DeploymentRecommendation/Step2");
// });
// const Deployment3 = lazy(() => {
//   return import("../Screen/DeploymentRecommendation/Step3");
// });

const ElectricalVehicle = lazy(() => {
  return import("../Screen/ElectricalVehicle");
});
const Infrastructure = lazy(() => {
  return import("../Screen/Infrastructure/InfrastructureRecommendation");
});
const IndividualCar = lazy(() => {
  return import("../Screen/Single");
});
const EvRecomIndividualCar = lazy(() => {
  return import("../Screen/DeploymentRecommendation/EvrecomIndividual");
});
const IndividualCar1 = lazy(() => {
  return import("../Screen/Step3individual/IndividualStep3");
});
const Individualstep3 = lazy(() => {
  return import("../Screen/IndividualStep3/Individualstep3");
});
const Error = lazy(() => {
  return import("../Components/404/Nodata");
});
const routesPath = {
  // Public Router Path without Authentication
  Public: [
    {
      path: SLUGS._login,
      component: <Login />,
    },
    {
      path: SLUGS._forget_password,
      component: <Forget />,
    },
    {
      path: SLUGS._new_password,
      component: <NewPassword />,
    },
  ],
  Private: [
    {
      path: SLUGS._dashboard,
      component: <Dashboard />,
    },
    {
      path: SLUGS._singleEvRecomend_individual,
      component: <EvRecomIndividualCar />,
    },
    {
      path: SLUGS._electrical_vehicle,
      component: <ElectricalVehicle />,
    },
    {
      path: SLUGS._infrastructure,
      component: <Infrastructure />,
    },
    {
      path: SLUGS._individual_car,
      component: <IndividualCar />,
    },
    {
      path: SLUGS._individual_car1,
      component: <IndividualCar1 />,
    },
    {
      path: SLUGS._individual_step3,
      component: <Individualstep3 />,
    },
    {
      path: SLUGS._deployment_recommendation,
      component: <Deployment />,
    },
    {
      path: SLUGS._analytics_dashboard,
      component: <AnalyticsDashboard />,
    },
    // {
    //   path: SLUGS._deployment_recommendation_overview,
    //   component: <Deployment1 />,
    // },
    // {
    //   path: SLUGS._deployment_recommendation_infrastructure,
    //   component: <Deployment2 />,
    // },
    // {
    //   path: SLUGS._deployment_recommendation_vehicles,
    //   component: <Deployment3 />,
    // },
    {
      path: SLUGS._error,
      component: <Error />,
    },
  ],
};

export default routesPath;
