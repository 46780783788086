import React from "react";
// import ErrorMessage from "./ErrorPage";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.setState({ error: error.message || "Unexpected error" });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="justify-center text-black items-center">
          <>
            <h2>Something went wrong</h2>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.error}
            </details>
          </>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
