const baseUrl = process.env.PUBLIC_URL;

// eslint-disable-next-line
export default {
  // Public Route
  _login: baseUrl + "/",
  _forget_password: baseUrl + "/forget/password/",
  _new_password: baseUrl + "/new/password/",

  // Private Route
  _dashboard: baseUrl + "/",
  _electrical_vehicle: baseUrl + "/electrical-vehicle/",
  _infrastructure: baseUrl + "/infrastructure-recommendation/",
  _individual_car: baseUrl + "/individual-car/",
  _individual_car1: baseUrl + "/individual-car1/",
  _individual_step3: baseUrl + "/partner-profile/",
  _analytics_dashboard: baseUrl + "/analytics-dashboard/",
  _deployment_recommendation: baseUrl + "/deployment/:pk/",
  _singleEvRecomend_individual: baseUrl + "/deployment/single-evRecomendcar/",
  // _deployment_recommendation_overview:
  //   baseUrl + "/deployment/ev-recommend-overview/",
  // _deployment_recommendation_infrastructure:
  //   baseUrl + "/deployment/recommendation-infrastructure/",
  // _deployment_recommendation_vehicles: baseUrl + "/deployment/vehicles/",
  _error: baseUrl + "/404",
};
