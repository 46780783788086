import Cookies from "universal-cookie";
const cookies = new Cookies();
const AuthToken = cookies.get("@evr_storage_access_token");

const baseAPi = process.env.REACT_APP_LOGIN_BASE_URL;
const baseAPi1 = process.env.REACT_APP_PRODUCTION_V;

const Config = {
  Api: {
    User_login: `${baseAPi}oauth/token/dashboard/`,
    forget_password: `${baseAPi}users/sendForgottenPasswordEmail/`,
    logout_user: `${baseAPi}oauth/revoke_token/`,
    table_section: `${baseAPi1}evrecommend/car_metrics/dashboard/`,
    dashboard: `${baseAPi1}evrecommend/fleet/dashboard/`,
    recommend_car: `${baseAPi1}evrecommend/recommendations/`,
    public_charging: `${baseAPi1}evrecommend/stations/`,
    Optimal_step1: `${baseAPi1}evrecommend/deployment/overview/optimal/`,
    User_selected_step1: `${baseAPi1}evrecommend/deployment/overview/user_selected/`,
    Optimal_step2: `${baseAPi1}evrecommend/deployment/infra_solutions/optimal/`,
    User_selected_step2: `${baseAPi1}evrecommend/deployment/infra_solutions/user_selected/`,
    Optimal_step3: `${baseAPi1}evrecommend/deployment/vehicles/optimal/`,
    User_selected_step3: `${baseAPi1}evrecommend/deployment/vehicles/user_selected/`,
    individual_step3: `${baseAPi1}evrecommend/deployment/stations/`,
    individual_car_optimal: `${baseAPi1}evrecommend/deployment/vehicles/optimal/`,
    individual_car_selected: `${baseAPi1}evrecommend/deployment/vehicles/user_selected/`,
    analyticsTab1: `${baseAPi1}evrecommend/report/fleet/`,
    analyticsTab2: `${baseAPi1}evrecommend/report/vehicles/`,
    analyticsTab3: `${baseAPi1}evrecommend/report/deployment/overview/`,
    analyticsTab4: `${baseAPi1}evrecommend/report/deployment/vehicles/`,
    analyticsTab5: `${baseAPi1}evrecommend/report/deployment/stations/`,
    EmmissionData: `${baseAPi}stats/fleet-stats/`,
    single_analyticsTab4: `${baseAPi1}evrecommend/deployment/vehicles/optimal/`,
  },

  Auth_token: AuthToken,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": window.localStorage.i18nextLng,
    Authorization: "Bearer " + AuthToken,
  },
  head: {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + AuthToken,
    },
  },
};

export default Config;
